// Vuetify
import 'vuetify/styles';
import { createVuetify, ThemeDefinition } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import { useTheme } from '@/composable/ui/useTheme';
import '@mdi/font/css/materialdesignicons.css';

const themeStore = useTheme();

const controlTheme: ThemeDefinition = {
  colors: themeStore.colors.value,
};

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    themes: {
      light: {
        colors: controlTheme.colors,
      },
    },
  },
});

export default vuetify;
