import { computed, ref } from 'vue';

export interface SnackbarState {
  isEnable: boolean,
  text: string,
  color: string,
}

const snackbarState = ref<SnackbarState | null>({
  isEnable: false,
  text: '',
  color: 'primary',
});

export function useSnackbar() {
  return {
    setSnackbar(snackbar: { text: string, color: string }) {
      snackbarState.value = { isEnable: true, ...snackbar };
    },
    disableSnackbar() {
      if (snackbarState.value) {
        snackbarState.value.isEnable = false;
      }
    },
    snackbar: computed(() => ({
      isEnable: snackbarState.value?.isEnable ?? false,
      text: snackbarState.value?.text ?? '',
      color: snackbarState.value?.color ?? 'primary',
    })),
  };
}
