import { computed, onBeforeUnmount, onMounted, reactive, ref} from 'vue';

export interface BreakpointOptions {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

// eslint-disable-next-line import/prefer-default-export
export function useBreakpoint(options?: BreakpointOptions) {
  const {
    xs: defaultXs = 600,
    sm: defaultSm = 960,
    md: defaultMd = 1264,
    lg: defaultLg = 1904,
  } = options || {};

  const width = ref(window.innerWidth);

  function onResize() {
    width.value = window.innerWidth;
  }

  onMounted(() => {
    window.addEventListener('resize', onResize);
  });
  onBeforeUnmount(() => {
    window.removeEventListener('resize', onResize);
  });

  const xs = computed(() => width.value < defaultXs);
  const sm = computed(() => width.value < defaultSm && !xs.value);
  const md = computed(() => width.value < defaultMd && !(xs.value || sm.value));
  const lg = computed(() => width.value < defaultLg && !(xs.value || sm.value || md.value));
  const xl = computed(() => width.value >= defaultLg);
  const xsOnly = computed(() => xs.value && !sm.value);
  const smOnly = computed(() => sm.value && !md.value);
  const smAndDown = computed(() => (xs.value || sm.value) && (!md.value));
  const smAndUp = computed(() => sm.value || md.value || lg.value || xl.value);
  const mdOnly = computed(() => md.value && !lg.value);
  const mdAndDown = computed(() => xs.value && !lg.value);
  const mdAndUp = computed(() => md.value || lg.value || xl.value);
  const lgOnly = computed(() => lg.value && !xl.value);
  const lgAndDown = computed(() => !xl.value);
  const lgAndUp = computed(() => lg.value);
  const xlOnly = computed(() => xl.value);

  const name = computed(() => {
    if (xs.value) return 'xs';
    if (sm.value) return 'sm';
    if (md.value) return 'md';
    if (lg.value) return 'lg';
    return 'xl';
  });

  return reactive({
    width,
    name,
    xs,
    sm,
    md,
    lg,
    xl,
    xsOnly,
    smOnly,
    smAndDown,
    smAndUp,
    mdOnly,
    mdAndDown,
    mdAndUp,
    lgOnly,
    lgAndDown,
    lgAndUp,
    xlOnly,
  });
}
