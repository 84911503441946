<template>
  <v-app>
    <router-view  />
    <base-snackbar
      :model-value="snackbarState.snackbar.value.isEnable"
      :timeout="3000"
      :color="snackbarState.snackbar.value.color"
      :text="snackbarState.snackbar.value.text"
      @update:modelValue="snackbarState.disableSnackbar"
    />
  </v-app>
</template>
<script lang="ts" setup>

import { useSnackbar } from '@/domain/states/useSnackbar';
import BaseSnackbar from '@/components/Snackbar/BaseSnackbar.vue';

const snackbarState = useSnackbar();

</script>
<style lang="scss">

html{
  overflow-y: unset;
}

.clickable {
  cursor: pointer;
}

</style>
