<template>
  <div class="base-text" :style="style">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>

import { computed, CSSProperties } from 'vue';
import { Colors, useTheme } from '@/composable/ui/useTheme';
import { useBreakpoint } from '@/composable/ui/useScreen';

const theme = useTheme();
const breakpoint = useBreakpoint();
const props = defineProps({
  size: {
    type: String as() => 'xs' |'sm' | 'md' | 'lg',
    default: 'md',
  },
  bold: Boolean,
  fontWight: {
    type: Number,
    default: 400,
  },
  color: { type: String as () => keyof Colors, default: 'body' },
  justify: {
    type: String as () => 'start' | 'center' | 'end',
    default: 'start',
  },
  requiredTag: {
    type: Boolean,
    default: false,
  },
  noWrap: Boolean,
});
const sizes = {
  xs: 12,
  sm: 14,
  md: 16,
  lg: 20,
};
const mobileSizes = {
  xs: 10,
  sm: 14,
  md: 16,
  lg: 20,
};

const textAligns = {
  start: 'left',
  center: 'center',
  end: 'right',
};

const style = computed((): CSSProperties => ({
  'font-size': `${breakpoint.smAndDown ? mobileSizes[props.size] : sizes[props.size]}px`,
  minHeight: `${sizes[props.size] + 10}px`,
  color: theme.resolveColor(props.color),
  textAlign: textAligns[props.justify] as CSSProperties['textAlign'],
  whiteSpace: props.noWrap ? 'nowrap' : 'pre-wrap',
}));

</script>

<style scoped>
div {
  letter-spacing: 1px;
  text-align: left;
  word-break: break-word;
}

</style>
